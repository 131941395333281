@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.roboto {
	font-family: "Roboto", sans-serif;
}

/* margins */

.mt-1 {
	margin-top: 10px;
}
.mt-2 {
	margin-top: 20px !important;
}
.mt-3 {
	margin-top: 30px;
}
.mt-4 {
	margin-top: 40px;
}
.mt-5 {
	margin-top: 50px;
}
.mt-6 {
	margin-top: 60px;
}
.mt-7 {
	margin-top: 70px;
}
.mt-8 {
	margin-top: 80px;
}
.mt-9 {
	margin-top: 90px;
}
.mt-10 {
	margin-top: 100px;
}

.mb-1 {
	margin-bottom: 10px;
}
.mb-2 {
	margin-bottom: 20px;
}
.mb-3 {
	margin-bottom: 30px;
}
.mb-4 {
	margin-bottom: 40px;
}
.mb-5 {
	margin-bottom: 50px;
}
.mb-6 {
	margin-bottom: 60px;
}
.mb-7 {
	margin-bottom: 70px;
}
.mb-8 {
	margin-bottom: 80px;
}
.mb-9 {
	margin-bottom: 90px;
}
.mb-10 {
	margin-bottom: 100px;
}

/* paddings */

.pt-1 {
	padding-top: 10px;
}
.pt-2 {
	padding-top: 20px;
}
.pt-3 {
	padding-top: 30px;
}
.pt-4 {
	padding-top: 40px;
}
.pt-5 {
	padding-top: 50px;
}
.pt-6 {
	padding-top: 60px;
}
.pt-7 {
	padding-top: 70px;
}
.pt-8 {
	padding-top: 80px;
}
.pt-9 {
	padding-top: 90px;
}
.pt-10 {
	padding-top: 100px;
}

.pb-1 {
	padding-bottom: 10px;
}
.pb-2 {
	padding-bottom: 20px;
}
.pb-3 {
	padding-bottom: 30px;
}
.pb-4 {
	padding-bottom: 40px;
}
.pb-5 {
	padding-bottom: 50px;
}
.pb-6 {
	padding-bottom: 60px;
}
.pb-7 {
	padding-bottom: 70px;
}
.pb-8 {
	padding-bottom: 80px;
}
.pb-9 {
	padding-bottom: 90px;
}
.pb-10 {
	padding-bottom: 100px;
}

.pr-20 {
	padding-left: 20px;
}

.underline {
	text-decoration: underline;
}

.bold {
	font-weight: bold;
}

.none {
	display: none !important;
}

.pointer {
	cursor: pointer;
}

.flex {
	display: flex;
}

.flex-wrap {
	flex-wrap: wrap;
}

.align-center {
	align-items: center;
}

.space-between {
	justify-content: space-between;
}

.gap-20 {
	gap: 20px;
}

.section-gray {
	background-color: #ececec;
}

.border-gray {
	width: 100%;
	border: 1px solid #ececec;
}

.text-center {
	text-align: center;
}

.red {
	color: #ff022c;
}

#notLogged {
	display: none;
}

.margin-elo {
	margin-top: -100px;
}
/* CONTAINER */

@media (min-width: 1401px) {
	.container {
		max-width: 1330px;
		margin: auto;
	}
}

@media (min-width: 1601px) {
	.container {
		max-width: 1580px;
		margin: auto;
	}
}

@media (min-width: 1901px) {
	.container {
		max-width: 1880px;
		margin: auto;
	}
}

@media (max-width: 1500px) {
	.container {
		max-width: 1400px;
		margin: auto;
	}
}

@media (max-width: 1400px) {
	.container {
		max-width: 1280px;
		margin: auto;
	}
}

@media (max-width: 1300px) {
	.container {
		max-width: 1200px;
		margin: auto;
	}
}

@media (max-width: 1200px) {
	.container {
		max-width: 1180px;
		margin: auto;
		padding: 20px;
	}
}

@media (max-width: 1024px) {
	.container {
		max-width: 1000px;
		margin: auto;
	}
}

@media (max-width: 1023px) {
	.container {
		max-width: 90%;
		margin: auto;
	}

	.sm.column {
		flex-direction: column;
	}
}

/* SLOGAN */

.slogan {
	width: 100%;
	position: absolute;
	text-align: center;
	z-index: 5;
	margin-top: 30px;
	color: #555;
}

.slogan b {
	color: #ec0818;
}

.slogan-button {
	padding: 7px 17px;
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	background: #ec0818;
	border-radius: 7px;
	margin-left: 20px;
}

@media (max-width: 1023px) {
	.slogan {
		width: 100%;
		margin-top: 10px;
		font-size: 14px;
	}
	.slogan-button {
		display: block;
		width: 70%;
		margin: 5px auto;
	}
}

/* BREADCRUMB */

.breadcrumb {
	display: flex;
	align-items: center;
}

.breadcrumb-active {
	font-size: 12px;
	line-height: 18px;
	color: #ec0818;
}

.breadcrumb-no-active {
	font-size: 12px;
	line-height: 18px;
	color: #555555;
}

.breadcrumb-arrow {
	width: 16px;
	height: 16px;
	background-color: #ececec;
	border-radius: 10px;
	text-align: center;
	line-height: 17px;
	color: #7b7b7b;
	margin: 0 15px;
}

@media (max-width: 426px) {
	.breadcrumb-arrow {
		margin: 0 7px;
	}
	.breadcrumb-no-active {
		font-size: 10px;
	}
	.breadcrumb-active {
		font-size: 10px;
	}
}

.slider {
	width: 100%;
	border-radius: 11px;
	margin-top: 30px;
}

.slider img {
	width: 100%;
	max-height: -webkit-fill-available;
	border-radius: 10px;
}

.left-side-div {
	width: 70%;
	word-break: break-word;
}

.left-side-div img {
	max-width: 100%;
	height: auto;
}

.right-side-div {
	width: 30%;
}

.box-sign {
	border: 1px solid #ececec;
	border-radius: 10px;
	padding: 40px;
}

.box-buy {
	border: 1px solid #ececec;
	border-radius: 10px;
	background: #f9f9f9;
	display: none;
}

.box-buy h2 {
	padding: 10px 40px 10px 40px;
}

.box-buy p {
	padding: 0 40px;
}

.red-button {
	background: #ec0818 0% 0% no-repeat padding-box;
	border-radius: 30px;
	color: #fff;
	padding: 10px;
	font-size: 16px;
	text-align: center;
	font-weight: bold;
	margin-top: 20px;
}

.box-program {
	border: 1px solid #ececec;
	border-radius: 10px;
	padding: 30px;
}

.orange-icon {
	color: #f58224;
	font-size: 30px;
	margin-right: 20px;
}

.yellow-icon {
	color: #e4b100;
	font-size: 16px;
	margin-right: 10px;
}

@media (max-width: 768px) {
	.left-side-div {
		width: 100%;
	}

	.left-side-div img {
		height: auto;
		max-width: 100%;
	}

	.slider {
		height: auto;
	}

	.right-side-div {
		width: 100%;
	}
}

.accordion-div {
}

.accordion-div .MuiTypography-root {
	color: #000 !important;
	font-family: PoppinsLight, Arial, sans-serif !important;
}

.accordion-div .MuiPaper-root {
	padding: 20px;
	box-shadow: none;
	border: 1px solid #ececec;
	border-radius: 10px;
}

@media (max-width: 500px) {
	.accordion-div .MuiPaper-root {
		padding: 0px;
	}
	.orange-icon {
		font-size: 19px;
	}
	.title-acc {
		font-size: 14px;
	}
}

.header-modal {
	background: rgb(242, 242, 242);
	margin-bottom: 15px;
	padding: 15px 25px;
	text-align: right;
}

.content-acc img {
	word-break: break-word;
	height: auto;
	max-width: 100%;
}

.content-acc * {
	word-break: break-word;
	max-width: 100%;
}

.body-modal {
}

.error-api {
	height: 500px;
	font-size: 18px;
}
